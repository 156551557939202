<template>
    <div class="article-content">
        <el-scrollbar class="article-wrap">
            <div class="top">
                <el-breadcrumb separator-class="el-icon-arrow-right" class="genera-breadcrumb">
                    <el-breadcrumb-item>网络推广</el-breadcrumb-item>
                    <el-breadcrumb-item>直播间管理</el-breadcrumb-item>
                    <el-breadcrumb-item>创建直播间</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <span class="content-title">创建直播间</span>
            <div class="main-content">
                <div class="content-left">
                    <div class="content-wrap" :style="{backgroundImage:'url('+form.cover+')'}" v-if="form.cover">
                        <div class="top-box">
                            <div class="left-box">
                                <i class="iconfont">&#xe611;</i>
                                <span class="top-text">Sketch</span>
                                <i class="iconfont">&#xe612;</i>
                            </div>
                            <div class="middle-box">
                                <span>{{topData}}</span>
                            </div>
                            <div class="right-box">
                                <span class="top-text">100%</span>
                                <i class="iconfont">&#xe643;</i>
                            </div>
                        </div>
                        <div class="middle-box">
                            <i class="el-icon-arrow-left"></i>
                            <div class="middle-content">
                                <div class="content-wrap">
                                    <img src="../../../../../assets/images/user_img.png">
                                    <span class="middle-text">包图姐姐的故事</span>
                                </div>
                            </div>
                        </div>
                        <div class="bottom-box">
                            <i class="iconfont">&#xe640;</i>
                            <i class="iconfont">&#xe626;</i>
                            <div class="orange-circle">
                                <i class="iconfont">&#xe641;</i>
                            </div>
                        </div>
                    </div>
                    <div class="left-title">直播间客户端模板</div>
                </div>
                <div class="content-right">
                    <el-form ref="form" :model="form" label-width="100px">
                        <el-form-item label="标题">
                            <el-input class="s500-input" v-model="form.title" maxlength="17" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item label="直播间封面" prop="analysis" class="subject-quill">
                            <span class="item-txt-tip">建议尺寸：1920px*1080px，图片大小不得超过3MB以内</span>
                            <el-upload
                                    class="cover-uploader"
                                    action="stuOp/liveUploadImg"
                                    name="img"
                                    :headers="headerParam"
                                    :show-file-list="false"
                                    :on-success="handleCoverSuccess"
                                    :before-upload="beforeCoverUpload">
                                <div class="inner-box">
                                    <div class="inner-content">
                                        <i class="el-icon-plus"></i>
                                        <span class="inner-text">选择封面</span>
                                    </div>
                                </div>
                                <div class="cover" v-if="form.cover">
                                    <img :src="form.cover">
                                    <div class="cover-bottom">重新上传</div>
                                </div>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="分享卡片封面" prop="analysis" class="subject-quill">
                            <span class="item-txt-tip">建议尺寸：600px*500px，图片大小不得超过1MB以内</span>
                            <el-upload
                                    class="cover-uploader"
                                    action="stuOp/liveUploadCardImg"
                                    name="img"
                                    :headers="headerParam"
                                    :show-file-list="false"
                                    :on-success="handleCardSuccess"
                                    :before-upload="beforeCardUpload">
                                <div class="inner-box">
                                    <div class="inner-content">
                                        <i class="el-icon-plus"></i>
                                        <span class="inner-text">上传图片</span>
                                    </div>
                                </div>
                                <div class="cover" v-if="form.card_cover">
                                    <img :src="form.card_cover" alt="">
                                    <div class="cover-bottom">重新上传</div>
                                </div>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="开播时间">
                            <el-col :span="11">
                                <el-date-picker type="datetime" placeholder="选择日期"
                                                v-model="form.live_broadcast_begin_time"
                                                value-format="yyyy-MM-dd HH:mm:ss"
                                                style="width: 100%;"></el-date-picker>
                            </el-col>
                            <el-col class="line" :span="2" style="text-align: center;color: #A0A0A0;">——</el-col>
                            <el-col :span="11">
                                <el-date-picker placeholder="选择日期" v-model="form.live_broadcast_end_time"
                                                value-format="yyyy-MM-dd HH:mm:ss"
                                                type="datetime"
                                                @change="selectOverTime"
                                                style="width: 100%;"></el-date-picker>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="主播昵称">
                            <el-input class="s500-input" v-model="form.anchor_name" maxlength="15"
                                      show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item label="主播微信号">
                            <div class="weixin-content">
                                <el-input class="s500-input" v-model="form.anchor_wechat"></el-input>
                                <span>仅用做为主播身份认证信息，不会在客户端展示，每个直播间可绑定一个。</span>
                            </div>
                        </el-form-item>
                        <el-form-item label="允许评论">
                            <el-switch
                                    v-model="form.is_allow_comments"
                                    active-text="是"
                                    inactive-text="否"
                                    active-value="1"
                                    inactive-value="0">
                            </el-switch>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="page-button">
                <el-button @click="goBack">返回</el-button>
                <el-button type="primary" @click="onSubmit" v-if="this.editId !== null">编辑直播间</el-button>
                <el-button type="primary" @click="onSubmit" v-else>创建直播间</el-button>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import {stuOpSaveLive, stuOpLiveDetail} from '@/utils/apis'

    export default {
        name: "createArticle",
        data() {
            return {
                topData: '',
                headerParam: {
                    Authorization: localStorage.getItem('studentToken') || ''
                },
                form: {
                    cover: '',
                    card_cover: '',
                    title: '',
                    anchor_name: '',
                    live_broadcast_begin_time: null,
                    live_broadcast_end_time: null,
                    anchor_wechat: '',
                    is_allow_comments: '0',
                },
                setting: {
                    menubar: false,
                    plugins: "lists, advlist,emoticons,fullscreen,table,insertdatetime,wordcount,image",
                    toolbar: [
                        'undo redo | styleselect | bold italic underline strikethrough|fontselect fontsizeselect formatselect|alignleft aligncenter alignright|bullist numlist|',
                        'image emoticons forecolor backcolor insertdatetime wordcount|table tabledelete|fullscreen|'
                    ],
                    language: 'zh_CN', //本地化设置
                    height: 300
                },
                opId: Number(this.$route.query.id) || null,
                courseId: Number(this.$route.query.course_id) || null,
                editId: Number(this.$route.query.edit_id) || null,
                platform: this.$route.meta.type,
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 3600 * 1000 * 24;
                    }
                },
            }
        },
        created() {
            let time1 = '', time2 = '';
            time1 = new Date().getHours();
            time2 = new Date().getMinutes();
            this.topData = time1 + ':' + time2;
            if (this.editId !== null) {
                this.getLiveDetail()
            }
        },
        methods: {
            getLiveDetail() {
                let param = {
                    id: this.editId
                }
                stuOpLiveDetail(param).then(res => {
                    this.form.title = res.data.title;
                    //后端传的带两个空格，就离谱
                    this.form.live_broadcast_begin_time = res.data.live_broadcast_begin_time.split('  ').join(' ');
                    this.form.live_broadcast_end_time = res.data.live_broadcast_end_time.split('  ').join(' ');
                    this.form.anchor_name = res.data.anchor_name;
                    this.form.anchor_wechat = res.data.anchor_wechat;
                    this.form.is_allow_comments = JSON.stringify(res.data.is_allow_comments);
                    this.form.cover = res.data.cover;
                    this.form.card_cover = res.data.card_cover;
                }).catch(err => {
                    console.error(err)
                })
            },
            selectOverTime() {
                if (!this.form.live_broadcast_begin_time) {
                    this.$message.warning('请先选择开始时间！');
                    this.form.live_broadcast_end_time = ''
                } else {
                    if (this.form.live_broadcast_end_time < this.form.live_broadcast_begin_time) {
                        this.$message.warning('请选择开始时间之后的日期！');
                        this.form.live_broadcast_end_time = ''
                    }
                }
            },
            backBtn() {
                this.$router.go(-1)
            },
            handleCoverSuccess(res, file) {
                if (res.code == 200) {
                    this.form.cover = res.data; //无域名
                    this.$message.success('上传成功')
                } else {
                    this.$message.error('上传失败，请稍后再试~')
                }
            },
            beforeCoverUpload(file) {
                const isSize = new Promise((resolve, reject) => {
                    const width = 1920;
                    const height = 1080;
                    const _URL = window.URL || window.webkitURL;
                    const img = new Image();
                    img.onload = () => {
                        const valid = img.width === width && img.height === height;
                        valid ? resolve() : reject();
                    };
                    img.src = _URL.createObjectURL(file);
                }).then(() => {
                        return file;
                    }, () => {
                        this.$message.warning('图片尺寸限制为1920px x 1080px');
                        return Promise.reject();
                    },
                );
                return isSize;
            },
            handleCardSuccess(res, file) {
                if (res.code == 200) {
                    this.form.card_cover = res.data; //无域名
                    this.$message.success('上传成功')
                } else {
                    this.$message.error('上传失败，请稍后再试~')
                }
            },
            beforeCardUpload(file) {
                const isSize = new Promise((resolve, reject) => {
                    const width = 600;
                    const height = 500;
                    const _URL = window.URL || window.webkitURL;
                    const img = new Image();
                    img.onload = () => {
                        const valid = img.width === width && img.height === height;
                        valid ? resolve() : reject();
                    };
                    img.src = _URL.createObjectURL(file);
                }).then(() => {
                        return file;
                    }, () => {
                        this.$message.warning('图片尺寸限制为600px x 500px');
                        return Promise.reject();
                    },
                );
                return isSize;
            },
            goBack() {
                this.$router.go(-1);
            },
            onSubmit() {
                let isErrNum = 0;
                if (this.form.title == '') {
                    isErrNum = 1
                }
                if (this.form.live_broadcast_begin_time == '') {
                    isErrNum = 1
                }
                if (this.form.live_broadcast_end_time == '') {
                    isErrNum = 1
                }
                if (this.form.cover == '') {
                    isErrNum = 1
                }
                if (this.form.card_cover == '') {
                    isErrNum = 1
                }
                if (this.form.anchor_name == '') {
                    isErrNum = 1
                }
                if (this.form.anchor_wechat == '') {
                    isErrNum = 1
                }
                if (this.form.is_allow_comments == '') {
                    isErrNum = 1
                }
                if (isErrNum === 1) {
                    this.$message.warning('每项为必填！')
                    return false
                } else {
                    let param = this.form;
                    if (this.platform == 0) {
                        param.op_id = this.opId;
                        param.course_id = this.courseId;
                    }
                    stuOpSaveLive(param).then(res => {
                        this.$message.success(res.msg);
                        if (this.platform == 0) {
                            this.$router.push({
                                name: 'studentTrainCenterStartTrainingLivePromotionIndex',
                                query: {
                                    id: this.opId,
                                    course_id: this.courseId,
                                }
                            })
                        } else {
                            this.$router.push({
                                name: 'studentExamCenterLivePromotion',
                            })
                        }
                    }).catch(err => {
                        console.error(err)
                    })
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .article-content {
        display: flex;
        flex-direction: column;
        height: calc(100% - 50px);
        padding: 0 0 40px;

        .article-wrap {
            height: 100%;
            display: flex;
            flex-direction: column;

            ::v-deep > .el-scrollbar__wrap {
                overflow-x: hidden;

                .el-scrollbar__view {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    padding: 0 20px;
                }
            }
        }

        .top {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #F1F5FF;
            height: 40px;
            padding-bottom: 10px;

            .genera-breadcrumb {
                display: flex;
                align-items: center;

                &:before {
                    content: unset;
                }
            }
        }

        .content-title {
            position: relative;
            font-size: 20px;
            color: #333333;
            margin: 20px 0 0 16px;

            &:before {
                position: absolute;
                content: '';
                width: 5px;
                height: 100%;
                background: #2DC079;
                left: -16px;
                border-radius: 3px;
            }
        }

        .main-content {
            margin-top: 20px;
            display: flex;

            .content-left {
                width: 375px;
                height: 667px;

                .content-wrap {
                    height: 100%;
                    width: 100%;
                    position: relative;

                    .top-box {
                        padding: 10px 5px;
                        display: flex;
                        justify-content: space-between;

                        i {
                            color: #ffffff;
                        }

                        .top-text {
                            margin: 0 5px;
                            color: #ffffff;
                        }

                        .middle-box {
                            color: #ffffff;
                        }

                        .right-box {
                            color: #ffffff;
                        }
                    }

                    .middle-box {
                        display: flex;

                        i {
                            font-size: 24px;
                            color: #ffffff;
                        }

                        .middle-content {
                            border-radius: 25px;
                            opacity: 0.3;
                            background: #251D16;
                            display: flex;
                            align-items: center;

                            .content-wrap {
                                margin: 5px;
                                display: flex;
                                align-items: center;

                                .middle-text {
                                    color: #ffffff;
                                    margin-left: 5px;
                                }
                            }

                            img {
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                            }
                        }
                    }

                    .bottom-box {
                        position: absolute;
                        bottom: 60px;
                        right: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        i {
                            font-size: 20px;
                            color: #ffffff;

                            &:nth-of-type(2) {
                                font-size: 24px;
                            }
                        }

                        .orange-circle {
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            background: #F96D01;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 20px;
                        }

                        i + i {
                            margin-top: 20px;
                        }
                    }
                }

                .left-title {
                    margin-top: 10px;
                    color: #999999;
                }
            }

            .content-right {
                display: flex;
                flex-direction: column;
                margin-left: 75px;

                .s500-input {
                    width: 500px;
                }
            }
        }

        .page-button {
            margin-top: 100px;
            text-align: center;
        }

        .cover-uploader {
            width: 500px;

            :deep(.el-upload) {
                display: flex;
                align-items: center;

                .inner-box {
                    width: 192px;
                    height: 108px;
                    background: #F7F7F7;
                    border-radius: 4px;
                    .inner-content {
                        padding: 29px 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        i {
                            font-size: 24px;
                            color: #BFBFBF;
                        }

                        .inner-text {
                            font-size: 14px;
                            color: #666666;
                        }
                    }
                }

                .cover {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 192px;
                    height: 108px;
                    margin-left: 15px;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    .cover-bottom {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 32px;
                        background: #000000;
                        opacity: 0.5;
                        border-radius: 2px;
                        color: #ffffff;
                        font-size: 16px;
                        line-height: 36px;
                    }
                }
            }
        }

        .item-txt-tip {
            color: #999999;
        }

        .weixin-content {
            display: flex;
            flex-direction: column;

            span {
                margin-top: 9px;
                color: #999999;
            }
        }
    }
</style>